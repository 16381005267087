



































































import {
  Component, Vue,
} from 'vue-property-decorator';

import SuspendContributionViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/recurring_deposits/suspend-contribution-view-model';

@Component({
  name: 'SuspendContribution',
  components: {
    SuspendContributionResult: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/SuspendContributionResult.vue'),
  },
})
export default class SuspendContribution extends Vue {
  suspend_contribution_view_model = Vue.observable(
    new SuspendContributionViewModel(this),
  );
}

