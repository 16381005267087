import TYPES from '@/types';

import Vue from 'vue';

// Application
import { GetAllianzAccountQuery } from '@/modules/my-investment/allianz/allianz-account/application/queries';
import {
  CancelRecurringContributionCommand,
} from '@/modules/my-investment/allianz/allianz-account/application/commands';
import {
  RecurringContributionCancelledEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/recurring-contribution-cancelled-entity';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class SuspendContributionViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.CANCEL_RECURRING_CONTRIBUTION_COMMAND)
  private readonly cancel_recurring_contribution_command!: CancelRecurringContributionCommand;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  private readonly view: Vue;

  i18n_namespace = 'components.allianz-dashboard.deposits.suspend_contribution';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  is_loading = false;

  recurring_contribution_cancelled_successfully = false;

  was_applied_for_current_month = false;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  cancel = () => {
    this.recurring_contribution_cancelled_successfully = false;
    this.was_applied_for_current_month = false;
    this.view.$emit('endProcess');
  }

  updateStateManagerAllianzAccount = async () => {
    try {
      const allianz_account = await this.get_allianz_account_query.execute(
        { customer_id: this.customer_id, reload: true },
      );
      const recurring_contributions_updated_event = new CustomEvent('allianz.update.recurring.contribution', { detail: { ...allianz_account } });
      window.dispatchEvent(recurring_contributions_updated_event);
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(
          this.translate('error_on_update_allianz_account'),
        );
      }
    }
  }

  confirm = async () => {
    this.is_loading = true;
    try {
      const response = await this.cancel_recurring_contribution_command
        .execute({ operation: 'cancel' }) as RecurringContributionCancelledEntity;
      this.was_applied_for_current_month = response.was_applied_for_current_month;
      this.recurring_contribution_cancelled_successfully = true;
      await this.updateStateManagerAllianzAccount();
    } catch {
      this.message_notifier.showErrorNotification(
        this.translate('error_on_cancel_recurring_contribution'),
      );
    } finally {
      this.is_loading = false;
    }
  }
}
